import React, { useEffect, useRef, useState } from 'react';
import GoogleMap from 'components/googlemap';
import { Box, Typography } from '@mui/material';

const Item2 = () => {
  const [markers, setMarkers] = useState([]);
  const mapRef = useRef(null);

  const onMapLoad = (map) => {
    mapRef.current = map;

    // 지도가 로드된 후에 radius를 계산하고 place를 가져옵니다.
    map.addListener('idle', () => {
      const bounds = map.getBounds();
      const center = map.getCenter();
      const radius = calculateRadius(bounds, center);
      clearMarkers(); // 기존 마커 제거
      fetchPlaces(map, radius, 'natural_feature', 'beach')
        .then(places => {
          displayPlaces(map, places); // 새로운 마커 표시
          // const sliced_cities = cities.slice(0, 2);
          // displayWeatherInfo(map, sliced_cities, map.getBounds());
        })
        .catch(error => {
          console.error('fetchVisibleCities error:', error);
        });
    });
  };

  const clearMarkers = () => {
    markers.forEach(marker => marker.setMap(null));
    setMarkers([]);
    console.log('clearMarkers');
  };

  const displayPlaces = (map, places) => {
    const newMarkers = places.map(place => {
      const marker = new window.google.maps.Marker({
        position: place.geometry.location,
        map: map,
        title: place.name
      });

      const infoWindow = new window.google.maps.InfoWindow({
        content: `<div><h3>${place.name}</h3><p>${place.vicinity}</p></div>`
      });

      marker.addListener('click', () => {
        infoWindow.open(map, marker);
      });

      return marker;
    });

    setMarkers(prevMarkers => [...prevMarkers, ...newMarkers]);
  };

  useEffect(() => {
    console.log(`marker set length: ${markers.length}`);
  }, [markers]);

  const fetchPlaces = (map, radius, placeType, keyword) => {
    return new Promise((resolve, reject) => {
      const service = new window.google.maps.places.PlacesService(map);
      const request = {
        location: map.getCenter(),
        radius: radius,
        type: placeType,
        keyword: keyword
      };

      service.nearbySearch(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          console.log(`google place(nearbySearch, ${placeType}, ${keyword}, ${radius}):`, results);
          resolve(results);
        } else {
          console.error(`PlacesServiceStatus not OK: ${status}`);
          reject(new Error(`PlacesServiceStatus not OK: ${status}`));
        }
      });
    });
  };

  const calculateRadius = (bounds, center) => {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const radius = window.google.maps.geometry.spherical.computeDistanceBetween(center, ne);
    return radius;
  };

  return <GoogleMap onMapLoad={onMapLoad} />;
};

export default Item2;